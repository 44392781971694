import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Heading from './Heading'
import breakpoint from '../utils/breakpoints'
import AuthorName from './PostAuthorName'
import Author from './PostAuthor'
import Avatar from './PostAuthorAvatar'
import Subtitle from './PostSubtitle'
import Date from './PostDate'

const Wrapper = styled.section`
  position: relative;
  height:300px;
  overflow: hidden;
  margin: calc(${props => props.theme.size.sectionSpacing} * -2 - 1px) calc(${props => props.theme.size.sectionSpacing} * -2 - 1px) calc(${props => props.theme.size.elementMargin} * 1.5) calc(${props => props.theme.size.sectionSpacing} * -2 - 1px);
  ${breakpoint.lessThan('large')`
    margin: calc(${props => props.theme.size.sectionSpacingSmall} * -1) calc(${props => props.theme.size.sectionSpacingSmall} * -1) ${props => props.theme.size.sectionSpacingSmall} calc(${props => props.theme.size.sectionSpacingSmall} * -1);
  `}
  ${breakpoint.lessThan('small')`
    height:200px;
  `}
`
const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height:300px;
  ${breakpoint.lessThan('small')`
    height:200px;
  `}
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background: rgba(74,57,88, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
`

const Title = styled(Heading.H1)`
  text-transform: capitalize;
  text-align: center;
  color: ${props => props.theme.colors.bgSection}!important;
  margin:0!important;
  width: 100%;
`

const HeroSubtitle = styled(Subtitle)`
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.bgSection};
  margin:0;
`

const MetaWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`

const Meta = styled.p`
  font-size: 0.7em;
  align-items: center;
  display: flex;
  justify-content: center;
  border-left: 0;
  margin: 0 0 ${props => props.theme.size.elementMargin} 0;
  padding-top: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.bgSection};
  margin:0;
  font-family: ${props => props.theme.font.familyHeading};
`

const Hero = props => {
  return(
    <Wrapper>
      <BgImg
        height={props.height}
        sizes={props.image.fluid}
        backgroundColor={'#eeeeee'}
      />
      <MetaWrapper>
      <Title>{props.title}</Title>
      <HeroSubtitle>{props.subTitle}</HeroSubtitle>
      <Meta>
        {props.date} by {props.author}
      </Meta>
      </MetaWrapper>
    </Wrapper>
  )
}

export default Hero
