import React from 'react'
import styled, { css } from 'styled-components';
import breakpoint from '../utils/breakpoints'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
  EmailShareButton,
} from 'react-share'
import { FacebookF as FacebookIcon } from 'styled-icons/fa-brands/FacebookF'
import { Twitter as TwitterIcon } from 'styled-icons/fa-brands/Twitter'
import { GooglePlusG as GoogleIcon } from 'styled-icons/fa-brands/GooglePlusG'
import { LinkedinIn as LinkedinIcon } from 'styled-icons/fa-brands/LinkedinIn'
import { PinterestP as PinterestIcon } from 'styled-icons/fa-brands/PinterestP'
import { RedditAlien as RedditIcon } from 'styled-icons/fa-brands/RedditAlien'
import { Envelope as MailIcon } from 'styled-icons/fa-solid/Envelope'
import { baseStyles as buttonStyles } from './Button'
import Heading from './Heading'
import Query from '../utils/mediaQuery'

const Wrapper = styled.div`
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction:row;
  padding: ${props => props.theme.size.elementMargin} 0 ${props => props.theme.size.elementMargin} 0;
  border-top: 1px solid ${props => props.theme.colors.border}
`

const ActionText = styled(Heading.H2)`
  margin:0;
  display: flex;
  justify-content: center;
  flex-direction:column;
`

const baseStyles = css`
  ${buttonStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  width:70px;
  padding:0.5em;
  &:first-child {
    margin-left:0;
  }
  &:last-child {
    margin-right:0;
  }

  ${breakpoint.lessThan('xsmall')`
    margin:0;
    width:60px;
  `}
`

const FacebookButton = styled(FacebookShareButton)`
  ${baseStyles}
  color: #3B5998!important;

  svg {
    height:2em;
  }
`

const TwitterButton = styled(TwitterShareButton)`
  ${baseStyles}
  color: #00aced!important;

  svg {
    height:2em;
  }
`

const LinkedinButton = styled(LinkedinShareButton)`
  ${baseStyles}
  color: #0077B5!important;

  svg {
    height:2em;
  }
`

const PinterestButton = styled(PinterestShareButton)`
  ${baseStyles}
  color: #C92228!important;

  svg {
    height:2em;
  }
`

const RedditButton = styled(RedditShareButton)`
  ${baseStyles}
  color: #ff4500!important;

  svg {
    height:2em;
  }
`

const MailButton = styled(EmailShareButton)`
  ${baseStyles}

  svg {
    height:2em;
  }
`

const Share = props => {
  const { url, text, media } = props
  return(
    <Wrapper>
      <FacebookButton url={url} quote={text}>
        <FacebookIcon />
      </FacebookButton>
      <Query lessThan="small">
        <TwitterButton url={url} title={text}>
          <TwitterIcon />
        </TwitterButton>
      </Query>
      <LinkedinButton url={url} title={text}>
        <LinkedinIcon />
      </LinkedinButton>
      <PinterestButton url={url} media={media}>
        <PinterestIcon />
      </PinterestButton>
      <Query lessThan="small">
        <RedditButton url={url} title={text}>
          <RedditIcon />
        </RedditButton>
      </Query>
      <MailButton url={url} subject={text}>
        <MailIcon />
      </MailButton>
    </Wrapper>
  )
}

export default Share;
