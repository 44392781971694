import React from 'react'
import Link from "./Link";
import styled from 'styled-components';
import breakpoint from '../utils/breakpoints'

const Li = styled.li`
  border-left: solid 1px ${props => props.theme.colors.border};
  display: inline-block;
  font-family: ${props => props.theme.font.familyHeading};
  font-size: 0.7em;
  font-weight: ${props => props.theme.font.weightHeading};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  line-height: 1;
  margin: 0 0 0 2em;
  padding: 0 0 0 2em;
  text-transform: uppercase;

  &:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }

  ${breakpoint.lessThan('xsmall')`
    margin: 0 0 0 1.25em;
    padding: 0 0 0 1.25em;
  `}
`

const Tag = props => {
  const { tag } = props
  return(
    <Li key={tag.id}>
      <Link to={`/tag/${tag.slug}/`} aria-label={tag.title}>{tag.title}</Link>
    </Li>
  )
}

export default Tag;
