import React from 'react'
import styled from 'styled-components'

const Main = styled.div`
  flex-grow: 1;
  -ms-flex: 1;
  width: 100%;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
`

const Container = props => {
  return <Main>{props.children}</Main>
}

export default Container
