import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Button, baseStyles} from './Button'
import 'whatwg-fetch' // Fetch Polyfill
import Heading from './Heading'

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Form = styled.form`
  margin-bottom:0;
  input {
    margin 0 0 0.5em 0;
    &:last-child {
      margin 0;
    }
  }
`

const ActionText = styled(Heading.H5)`
  font-family: ${props => props.theme.font.familyHeading};
  font-weight: 400!important;
  text-align: center;
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Submit = styled.input`
  ${baseStyles};
`

const ModalOverlay = styled.div`
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
  background-color: rgba(0,0,0,0.3);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

const Modal = styled.div`
  background: ghostwhite;
  padding: 2em;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class NewsletterSignup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      showModal: false,
      submitting: false
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    this.setState({
      submitting: true
    })
    fetch('/.netlify/functions/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({ email: this.state.email })
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      email: '',
      showModal: true,
      submitting: false
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <div>
        <Heading.H4 handwriting tac looksLike="H1" mb="0" mt="-0.2em">Newsletter</Heading.H4>
        <ActionText>
          Get exclusive content and updates, directly in your inbox!
        </ActionText>
        <Form
          onSubmit={this.handleSubmit}
          overlay={this.state.showModal}
          onClick={this.closeModal}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot" onChange={this.handleInputChange} />
            </label>
          </p>
          <Email
            name="email"
            type="email"
            title="Email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
          <Submit name="submit" type="submit" value={this.state.submitting ? "Submitting..." : "Sign Up"} disabled={this.state.submitting} fit important />
          <ModalOverlay onClick={this.closeModal} visible={this.state.showModal} />
          <Modal visible={this.state.showModal}>
            <p>
              Please check your inbox in order to confirm your subscription to our newsletter.
            </p>
            <Button onClick={this.closeModal}>Okay</Button>
          </Modal>
        </Form>
      </div>
    )
  }
}

NewsletterSignup.propTypes = {
  data: PropTypes.object,
}

export default NewsletterSignup
