const theme = {
  colors: {
    bg: "palevioletred",
    bgAlt: "whitesmoke",
    bgSection: "white",
    bgSectionAlt: "#B29AB7",
    fg: "#646464",
    fgBold: "#3c3b3b",
    fgLight: "#F3F2EE",
    border: "rgba(74,57,88,0.3)",
    borderBg: "rgba(74,57,88,0.075)",
    borderAlt: "rgba(74,57,88,0.65)",
    accent: "#4A3958",
    fbCommentsColorscheme: "light"
  },
  font: {
    family: "'Source Sans Pro', Helvetica, sans-serif",
    familyFixed: "'Courier New', monospace",
    familyHeading: "'Raleway', Helvetica, sans-serif",
    familyHandwriting: "'Rochester', cursive",
    weight: "400",
    weightBold: "700",
    weightHeading: "400",
    weightHeadingBold: "700",
    kerningHeading: "0.05em",
    preload: [
      'raleway-latin-400',
      'raleway-latin-700',
      'rochester-latin-400',
      'source-sans-pro-latin-400',
      'source-sans-pro-latin-700'
    ]
  },
  size: {
		elementHeight: "2.75em",
		elementMargin: "1.5em",
		sectionSpacing: "1.5em",
		sectionSpacingSmall: "1.5em",
		menu: "25em"
	},
  sizes: {
    maxWidth: '1200px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  breakpoints: {
    xlarge: "1680px",
		large:  "1024px",
		medium: "768px",
		small: "425px",
		xsmall: "375px"
  },
  duration: {
		menu:	"0.5s",
		transition:	"0.2s"
	}
}

export default theme
