import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`

const PreviousLink = styled(Button)`
  align-self: flex-start;
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Button)`
  align-self: flex-end;
  margin-left: auto;
  order: 2;
`

const PostLinks = props => {
  return (
    <Wrapper>
      {props.previousSlug && (
        <PreviousLink to={`/${props.previousSlug}/`}>Prev</PreviousLink>
      )}
      {props.nextSlug && <NextLink to={`/${props.nextSlug}/`}>Next</NextLink>}
    </Wrapper>
  )
}

export default PostLinks
