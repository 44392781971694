import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import find from 'lodash/find'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Hero from '../components/Hero'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import Tag from '../components/Tag'
import Stats from '../components/PostStats'
import PostLinks from '../components/PostLinks'
import PostDate from '../components/PostDate'
import SEO from '../components/SEO'
import Share from '../components/Share'
import Article from '../components/Article'
import PostComments from '../components/PostComments'

const PostTemplate = ({ data }) => {
  const {
    title,
    subTitle,
    slug,
    id,
    heroImage,
    body,
    publishDate,
    tags,
    author,
  } = data.contentfulPost
  const postNode = data.contentfulPost

  const postIndex = find(
    data.allContentfulPost.edges,
    ({ node: post }) => post.id === id
  )

  const { previous, next } = postIndex

  return (
    <Layout>
      <Helmet>
        <title>{`${title} | ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />

      <Article>
        <Hero title={title} image={heroImage} author={author.authorName} subTitle={subTitle} publishDate={publishDate} avatar={author.avatar} date={publishDate}/>
        <PageBody body={body} />
        <Stats>
          <TagList post>
            {tags.map(tag => (
              <Tag key={tag.id} tag={tag}></Tag>
            ))}
          </TagList>
        </Stats>
        <Share text={title} url={`${config.siteUrl}/${slug}/`} media={heroImage.ogimg.src} />
        <PostComments slug={slug} />
        <PostLinks previousSlug={previous !== null ? previous.slug:''} nextSlug={next !== null ? next.slug:''} />
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query postQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      subTitle
      id
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      author {
        authorName
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
    allContentfulPost(
      limit: 1000
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
        }
        previous {
          slug
        }
        next {
          slug
        }
      }
    }
  }
`

export default PostTemplate
