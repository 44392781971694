import styled from 'styled-components';

const Author = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 0;
  font-family: ${props => props.theme.font.familyHeading};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  white-space: nowrap;
  margin-bottom: 0;
`

export default Author;
