import styled from 'styled-components';
import breakpoint from '../utils/breakpoints'

const Stats = styled.div`
  cursor: default;
  list-style: none;
  padding: 0;
  margin: 0;

  ${breakpoint.lessThan('xsmall')`
    margin: 0 0 ${props => props.theme.size.elementMargin} 0;
    text-align: center;
  `}
`

export default Stats;
