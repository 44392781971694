import Img from 'gatsby-image'
import styled from 'styled-components';

const Avatar = styled(Img)`
  border-radius: 100%;
  display: block;
  width: 3em;
  margin-right:1.5em;
`

export default Avatar;
