import React from 'react'
import Link from "./Link";
import styled, { css } from 'styled-components';

const baseStyles = css`
  transition:
    background-color ${props => props.theme.duration.transition} ease,
    box-shadow ${props => props.theme.duration.transition} ease,
    color ${props => props.theme.duration.transition} ease
  ;
  border: 0;
  appearance: none;
  background-color: white;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.fgBold} !important;
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.theme.font.familyHeading};
  font-size:14pt;
  height: 3.25em;
  line-height:3.25em;
  font-weight: ${props => props.theme.font.weightBold};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 0;

  &:hover {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.accent};
    color: ${props => props.theme.colors.accent} !important;

    &:active {
      background-color: transparentize(${props => props.theme.colors.accent}, 0.95);
    }
  }

  ${props => props.important && css`
    color: ${props => props.theme.colors.bgSection} !important;
    box-shadow: none;
    background-color: ${props => props.theme.colors.bg};
    &:hover {
      box-shadow: inset 0 0 0 2px ${props => props.theme.colors.bg};
      color: ${props => props.theme.colors.bg} !important;
      background-color: ${props => props.theme.colors.bgSection};

      &:active {
        background-color: transparentize(${props => props.theme.colors.bgSection}, 0.95);
      }
    }
  `}

  ${props => props.fit && css`
    width: 100%;
  `}

  ${props => props.disabled && css`
    pointer-events: none;
    color: ${props => props.theme.colors.border} !important;

    &:before {
      color: ${props => props.theme.colors.border} !important;
    }
  `}

  &:before, &:after {
    color: ${props => props.theme.colors.fgLight};
    position: relative;
  }

  &:before {
    left: -1em;
    padding: 0 0 0 0.75em;
  }

  &:after {
    left: 1em;
    padding: 0 0.75em 0 0;
  }
`
const StyledLink = styled(Link)`
  border: 0;
`

const StyledButtonDiv = styled.div`
  ${baseStyles}
`
const StyledButton = styled.button`
  ${baseStyles}
`

const Button = ({ children, to, className, ...other }) => {
  if (to) {
    return (
      <StyledLink to={to} className={className}>
        <StyledButtonDiv {...other}>
          {children}
        </StyledButtonDiv>
      </StyledLink>
    );
  }
  return (
    <StyledButton {...other}>
      {children}
    </StyledButton>
  );
};

export { Button, baseStyles };
