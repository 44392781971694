import styled, {css} from 'styled-components';
import breakpoint from '../utils/breakpoints'

const SidebarItem = styled.section`
  padding: ${props => props.padding ? props.padding : props.theme.size.sectionSpacingSmall};
  background: ${props => props.backgroundColor ? props.backgroundColor : props.theme.colors.bgSection};
  border: solid 1px ${props => props.theme.colors.border};
  margin: 0 0 ${props => props.theme.size.sectionSpacing} 0;
  position: relative;

  ${props => props.bt && css`
    border-top: ${props => props.bt};
  `}

  ${breakpoint.lessThan('small')`
    margin: 0 0 ${props => props.theme.size.elementMargin} 0;
  `}
`

export default SidebarItem;
