import React from 'react'
import Link from "./Link";
import styled from 'styled-components';
import breakpoint from '../utils/breakpoints'

const Article = styled.article`
  padding: calc(${props => props.theme.size.sectionSpacing} * 2);
  background: ${props => props.theme.colors.bgSection};
  border: solid 1px ${props => props.theme.colors.border};
  margin: 0 0 ${props => props.theme.size.sectionSpacing} 0;
  position: relative;

  ${breakpoint.lessThan('large')`
    padding: ${props => props.theme.size.sectionSpacingSmall} ${props => props.theme.size.sectionSpacingSmall} ${props => props.theme.size.sectionSpacingSmall} ${props => props.theme.size.sectionSpacingSmall};
  `}

  ${breakpoint.lessThan('medium')`
    border-left: 0;
    border-right: 0;
    left: calc(${props => props.theme.size.sectionSpacingSmall} * -1);
    width: calc(100% + ${props => props.theme.size.sectionSpacingSmall} * 2);
  `}

  ${breakpoint.lessThan('small')`
    left: calc(${props => props.theme.size.sectionSpacingSmall} * -1);
    margin: 0 0 ${props => props.theme.size.elementMargin} 0;
    width: calc(100% + ${props => props.theme.size.sectionSpacingSmall} * 2);
  `}
`

export default Article;
