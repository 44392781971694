import React from 'react'
import config from '../utils/siteConfig'
import theme from '../styles/theme'
import styled from 'styled-components'
import { FacebookProvider, Comments } from "react-facebook";
import Heading from './Heading'

const Wrapper = styled.div`
  padding: ${props => props.theme.size.elementMargin} 0 ${props => props.theme.size.elementMargin} 0;
  border-top: 1px solid ${props => props.theme.colors.border};
`

const PostComments = props => {
  const { slug } = props;
  const { facebook, siteUrl } = config

  return (
    <Wrapper>
      <Heading.H2>Comments</Heading.H2>
      <FacebookProvider appId={facebook}>
        <Comments
          href={`${siteUrl}/${slug}/`}
          width="100%"
          colorScheme={theme.colors.fbCommentsColorscheme}
        />
      </FacebookProvider>
    </Wrapper>
  );
};

export default PostComments;
