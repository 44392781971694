import { createGlobalStyle } from 'styled-components'
import theme from './theme'
import breakpoint from '../utils/breakpoints'
import "typeface-source-sans-pro"
import "typeface-raleway"
import "typeface-rochester"

export default createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  a {
    -moz-transition: color 0.2s ease, border-bottom-color 0.2s ease;
    -webkit-transition: color 0.2s ease, border-bottom-color 0.2s ease;
    -ms-transition: color 0.2s ease, border-bottom-color 0.2s ease;
    transition: color 0.2s ease, border-bottom-color 0.2s ease;
    border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
    color: inherit;
    text-decoration: none;
  }

  /* Added to Fix Footer to bottom of viewport */
  html, body {
    height: 100%;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    padding-top: 2.5em;
    flex: 1 0 auto;
  }

  ul {
    list-style: none;
    margin: 0 0 ${theme.size.elementMargin} 0;
  }

  ol {
    margin-left: 1em;
  }

  blockquote, q {
    margin: 0 0 ${theme.size.sectionSpacingSmall} 0;
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body, input, select, textarea {
		color: ${theme.colors.fg};
		font-family: ${theme.font.family};
		font-size: 16pt;
		font-weight: ${theme.font.weight};
		line-height: 1.75;

    ${breakpoint.lessThan('xlarge')`
      font-size: 16pt;
    `}
    ${breakpoint.lessThan('large')`
      font-size: 16pt;
    `}
    ${breakpoint.lessThan('medium')`
      font-size: 14pt;
    `}
    ${breakpoint.lessThan('small')`
      font-size: 12pt;
    `}
    ${breakpoint.lessThan('xsmall')`
      font-size: 12pt;
    `}
	}

  body {
    background: ${theme.colors.bgAlt};
  }

  p {
		margin: 0 0 ${theme.size.sectionSpacingSmall} 0;
    &:last-child {
      margin: 0;
    }
	}

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  form {
		margin: 0 0 ${theme.size.elementMargin} 0;
	}

	label {
		color: ${theme.colors.fgBold};
		display: block;
		font-size: 0.9em;
		font-weight: ${theme.font.weightBold};
		margin: 0 0 calc(${theme.size.elementMargin} * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
  input[type="search"],
	input[type="tel"],
	select,
	textarea {
		appearance: none;
		background: ${theme.colors.bgSection};
		border: none;
		border: solid 1px ${theme.colors.border};
		border-radius: 0;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			border-color: ${theme.colors.accent};
			box-shadow: inset 0 0 0 1px ${theme.colors.accent};
		}
	}

	.select-wrapper {
		display: block;
		position: relative;

		&:before {
			color: ${theme.colors.border};
			content: '\f078';
			display: block;
			height: ${theme.size.elementMargin};
			line-height: ${theme.size.elementMargin};
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: ${theme.size.elementMargin};
		}

		select::-ms-expand {
			display: none;
		}
	}

	select {
		background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'><path d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='${theme.colors.border}' /></svg>");
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: ${theme.size.elementHeight};
		padding-right: ${theme.size.elementHeight};
		text-overflow: ellipsis;

		option {
			color: ${theme.colors.fgBold};
			background: ${theme.colors.bg};
		}

		&:focus {
			&::-ms-value {
				background-color: transparent;
			}
		}

		&::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
  input[type="search"],
	select {
		height: ${theme.size.elementHeight};
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"], {
		appearance: none;
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;

		& + label {
			color: ${theme.colors.fg};
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: ${theme.font.weight};
			padding-left: calc(${theme.size.elementHeight} * 0.6) + 0.75em;
			padding-right: 0.75em;
			position: relative;

			&:before {
				background: ${theme.colors.borderBg};
				border: solid 1px ${theme.colors.border};
				content: '';
				display: inline-block;
				height: calc(${theme.size.elementHeight} * 0.6);
				left: 0;
				line-height: calc(${theme.size.elementHeight} * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: calc(${theme.size.elementHeight} * 0.6);
			}
		}

		&:checked + label {
			&:before {
				background: ${theme.colors.fgBold};
				border-color: ${theme.colors.fgBold};
				color: ${theme.colors.bg};
				content: '\f00c';
			}
		}

		&:focus + label {
			&:before {
				border-color: ${theme.colors.accent};
				box-shadow: 0 0 0 1px ${theme.colors.accent};
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: ${theme.colors.fgLight} !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: ${theme.colors.fgLight} !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: ${theme.colors.fgLight} !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: ${theme.colors.fgLight} !important;
		opacity: 1.0;
	}
`
