import styled from 'styled-components';

const Subtitle = styled.p`
  font-family: ${props => props.theme.font.familyHeading};
  font-size: 0.7em;
  font-weight: ${props => props.theme.font.weightHeading};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  line-height: 2.5;
  margin-top: -1em;
  text-transform: uppercase;
`

export default Subtitle;
