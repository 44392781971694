import React from 'react'
import config from '../utils/siteConfig'
import Link from './Link'
import styled from 'styled-components'
import { FacebookF as FacebookIcon } from 'styled-icons/fa-brands/FacebookF'
import { Instagram as InstagramIcon } from 'styled-icons/fa-brands/Instagram'

const Wrapper = styled.div`
  height:5em;
`

const StyledFacebookIcon = styled(FacebookIcon)`
  width:1.5em;
  height:5em;
`

const StyledInstagramIcon = styled(InstagramIcon)`
  width:2.5em;
  height:5em;
`

const Facebook = styled(Link)`
  background-color:#3B5998;
  color: ${props => props.theme.colors.bgSection};
  width:50%;
  display:inline-block;
  text-align:center;
  border:none;
`

const Instagram = styled(Link)`
  background-color:#e4405f;
  color: ${props => props.theme.colors.bgSection};
  width:50%;
  display:inline-block;
  text-align:center;
  border:none;
`

const Social = props => {
  return(
    <Wrapper>
      <Facebook to={config.facebookUrl} aria-label={`MOMNOTEBOOK on Facebook`}><StyledFacebookIcon /></Facebook>
      <Instagram to={config.instagramUrl} aria-label={`MOMNOTEBOOK on Instagram`}><StyledInstagramIcon /></Instagram>
    </Wrapper>
  )
}

export default Social;
