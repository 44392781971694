import styled from 'styled-components';

const Date = styled.time`
  display: block;
  text-align:right;
  font-family: ${props => props.theme.font.familyHeading};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  white-space: nowrap;
  margin: 0;
`

export default Date;
