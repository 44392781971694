import styled, { css } from 'styled-components';

const baseStyles = css`
  color: ${props => props.theme.colors.fgBold};
  font-family: ${props => props.theme.font.familyHeading};
  font-weight: ${props => props.theme.font.weightHeadingBold};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  line-height: 1.65;
  margin: 0 0 calc(${props => props.theme.size.elementMargin} * 0.5) 0;

  a {
    color: inherit;
    border-bottom: 0;
  }
  color: ${props => props.theme.colors.black};
  line-height: 1.2;
  ${props => props.handwriting && css`
    font-family: ${props => props.theme.font.familyHandwriting};
  `}
  ${props => props.tac && css`
    text-align:center;
  `}
  ${props => props.mb && css`
    margin-bottom:${props => props.mb};
  `}
  ${props => props.mt && css`
    margin-top:${props => props.mt};
  `}
  ${props => props.looksLike === "H1" && css`
    font-size: 2em;
  `}
  ${props => props.looksLike === "H2" && css`
    font-size: 1.5em;
  `}
  ${props => props.looksLike === "H3" && css`
    font-size: 1.25em;
  `}
  ${props => props.xlarge && css`
    font-size: 3em;
  `}
`;

const H1 = styled.h1`
  font-size: 2em;
  ${baseStyles}
`;

const H2 = styled.h2`
  font-size: 1.5em;
  ${baseStyles};
`;

const H3 = styled.h3`
  font-size: 1.25em;
  ${baseStyles};
`;

const H4 = styled.h4`
  font-size: 1em;
  ${baseStyles};
`;

const H5 = styled.h5`
  font-size: 1em;
  ${baseStyles};
`;

const H6 = styled.h6`
  font-size: 1em;
  ${baseStyles};
`;

const Heading = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6
};

export default Heading;
