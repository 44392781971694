import React from 'react'
import styled from 'styled-components'
import breakpoint from '../utils/breakpoints'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  transition: opacity ${props => props.theme.duration.menu} ease;
  margin: 0 auto;
  max-width: 100%;
  opacity: 1;
  width: 1440px;
  padding: ${props => props.theme.size.sectionSpacing};

  ${breakpoint.lessThan('large')`
    padding: ${props => props.theme.size.sectionSpacingSmall};
    display: block;
  `}

  ${breakpoint.lessThan('medium')`
    padding: ${props => props.theme.size.sectionSpacingSmall};
  `}

  ${breakpoint.lessThan('xsmall')`
    padding: ${props => props.theme.size.sectionSpacingSmall};
  `}

  body.single & {
    display: block;
  }
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
