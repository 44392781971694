import React from 'react'
import styled, {css} from 'styled-components'

const List = styled.ul`
  text-align:right;
  margin: 0;
  ${props => props.post && css`
    margin: 0 0 ${props => props.theme.size.elementMargin} 0;
  `}
  ${props => props.sidebar && css`
    list-style-type: none;
    list-style-position: inside;
    text-align:center;
    li {
      display:list-item;
      padding: 0;
      margin:auto;
      border:0;
      line-height: 1.75;
    }
  `}
`

const TagList = props => {
  return <List sidebar={props.sidebar} post={props.post}>{props.children}</List>
}

export default TagList
