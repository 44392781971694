import React, {Component} from 'react'
import Link from './Link'
import styled from 'styled-components'
import breakpoint from '../utils/breakpoints'
import Heading from './Heading'
import Query from '../utils/mediaQuery'
import { Search as SearchIcon } from 'styled-icons/octicons/Search'
import { Menu as BurgerMenuIcon } from 'styled-icons/material/Menu'
import { slide as BurgerMenu } from 'react-burger-menu'

const StyledHeader = styled.header`
  display:flex;
  justify-content:space-between;
  background-color: ${props => props.theme.colors.bg};
  height: 2.5em;
  height: calc(2.5em + constant(safe-area-inset-top));
  height: calc(2.5em + env(safe-area-inset-top));
  line-height: 2.2em;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  color: ${props => props.theme.colors.bgSection};
  /* Status bar height on iOS 10 */
  padding-top: 20px;
  /* Status bar height on iOS 11.0 */
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  padding-top: env(safe-area-inset-top);

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      display: inline-block;
      padding-left: 0;
    }
  }

  h1 {
    color: inherit;
    height: inherit;
    line-height: inherit;
    padding: 0 0 0 0.35em;
    white-space: nowrap;

    a {
      font-size: 0.7em;
    }
  }
`
const Links = styled.nav`
  flex:1;
  height: inherit;
  line-height: inherit;
  overflow: hidden;

  ul {
    li {
      border-left: solid 1px ${props => props.theme.colors.border};
      line-height: 2.5em;
      margin-right: 1em;
      padding-right: 1em;
      float:right;

      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }

      a {
        border-bottom: 0;
        font-family: ${props => props.theme.font.familyHeading};
        font-size: 14pt;
        font-weight: ${props => props.theme.font.weightBold};
        letter-spacing: ${props => props.theme.font.kerningHeading};
        text-transform: uppercase;
      }
    }
  }

  ${breakpoint.lessThan('medium')`
    display:none;
  `}
`

const Main = styled.nav`
  height: inherit;
  line-height: inherit;
  text-align: right;

  ul {
    height: inherit;
    line-height: inherit;

    li {
      height: inherit;
      line-height: inherit;
      white-space: nowrap;

      > * {
        display: block;
        float: left;
      }

      > a {
        border-bottom: 0;
        color: inherit;
        overflow: hidden;
        position: relative;
        width: 2.5em;
        text-align:center;
        line-height: 2.3em;

        &:before {
          display: block;
          height: inherit;
          left: 0;
          line-height: inherit;
          position: absolute;
          text-align: center;
          text-indent: 0;
          top: 0;
          width: inherit;
        }
      }
    }
  }
`

const StyledBurgerMenu = styled.div`
  line-height: 2.3em;
  .bm-burger-button {
    position:relative;
    width:2.5em;
    text-align:center;
  }
  .bm-icon {
    width:1.5em!important;
    color: ${props => props.theme.colors.bgSection};
  }
  .bm-burger-bars {
    background:#373a47;
  }
  .bm-cross-button {
    width:1.5em;
  }
  .bm-cross {
    background:${props => props.theme.colors.bgSection};
  }
  .bm-menu {
    background:#4A3958;
    padding:2.5em 1.5em 0;
    font-size:1.15em;
  }
  .bm-morph-shape {
    fill:#373a47;
  }
  .bm-item-list {
    color:${props => props.theme.colors.bgSection};
    padding:0.8em;
  }
  .bm-item {
    display:inline-block;
    text-align:center;
  }
  .bm-overlay {
    background:rgba(0, 0, 0, 0.3);
    top:0;
    left:0;
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  width:1.0em;
`

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  closeMenu () {
    this.setState({menuOpen: false})
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }

  render() {
    const {burgerMenu, topMenu} = this.props
    return (
      <StyledHeader>
        <Heading.H1><Link to="/">MOMNOTEBOOK</Link></Heading.H1>
        <Links>
          <ul>
            {topMenu.map(({ node: menuItem }) => (
              <li key={menuItem.id}><Link to={menuItem.linkTo} aria-label={menuItem.title}>{menuItem.title}</Link></li>
            ))}
          </ul>
        </Links>
        <Main>
          <ul>
            <li>
              <Link to="/search/" aria-label="Search">
                <StyledSearchIcon />
              </Link>
            </li>
            <li>
              <StyledBurgerMenu>
                <BurgerMenu
                  customBurgerIcon={ <BurgerMenuIcon /> }
                  right
                  isOpen={this.state.menuOpen}
                  onStateChange={(state) => this.handleStateChange(state)}
                >
                  <Link to="/search/" aria-label="Search" onClick={() => this.closeMenu()}><StyledSearchIcon /> Search</Link>
                  {burgerMenu.map(({ node: menuItem }) => (
                    <Link key={menuItem.id} to={menuItem.linkTo} onClick={() => this.closeMenu()} aria-label={menuItem.title}>{menuItem.title}</Link>
                  ))}
                </BurgerMenu>
              </StyledBurgerMenu>
            </li>
          </ul>
        </Main>
      </StyledHeader>
    )
  }
}

export default Header
