import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import config from '../utils/siteConfig'
import { FacebookSquare as FacebookIcon } from 'styled-icons/fa-brands/FacebookSquare'
import { TwitterSquare as TwitterIcon } from 'styled-icons/fa-brands/TwitterSquare'
import { Instagram as InstagramIcon } from 'styled-icons/fa-brands/Instagram'
import { RssSquare as RssIcon } from 'styled-icons/fa-solid/RssSquare'
import { Envelope as MailIcon } from 'styled-icons/fa-solid/Envelope'

const FooterWrapper = styled.section`
  border-top: solid 1px ${props => props.theme.colors.border};
  margin: ${props => props.theme.size.sectionSpacing} 0 0 0;
  padding: ${props => props.theme.size.sectionSpacing} 0 0 0;
`

const Copyright = styled.p`
  color: ${props => props.theme.colors.fg};
  font-family: ${props => props.theme.font.familyHeading};
  font-size: 0.5em;
  font-weight: ${props => props.theme.font.weightHeading};
  letter-spacing: ${props => props.theme.font.kerningHeading};
  text-transform: uppercase;
  text-align: center;
`

const Icons = styled.ul`
  color: ${props => props.theme.colors.fg};
  display: flex;
  justify-content: space-between;
`

const IconWrapper = styled.li`
	margin: 0;
  vertical-align: baseline;

  a {
    border: 0;
  }
  svg {
    height: 1em;
  }
`

const StyledMailIcon = styled(MailIcon)`
  width:1.2em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.base};
    }
  }
`

const Footer = () => (
  <FooterWrapper>
    <Icons>
      <IconWrapper><Link to={config.facebookUrl} aria-label="MOMNOTEBOOK on Facebook"><FacebookIcon /></Link></IconWrapper>
      <IconWrapper><Link to={config.instagramUrl} aria-label="MOMNOTEBOOK on Instagram"><InstagramIcon /></Link></IconWrapper>
      <IconWrapper><Link to={`${config.siteUrl}/rss.xml`} aria-label="RSS feed"><RssIcon /></Link></IconWrapper>
      <IconWrapper><Link to={`/contact/`} aria-label="Contact"><StyledMailIcon /></Link></IconWrapper>
    </Icons>
    <Copyright>&copy; momnotebook.com</Copyright>
  </FooterWrapper>
)

export default Footer
