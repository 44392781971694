import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from 'styled-components'
import config from '../utils/siteConfig'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import Wrapper from '../components/Wrapper'
import Main from '../components/Main'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Social from '../components/Social'
import SidebarItem from '../components/SidebarItem'
import Heading from '../components/Heading'
import TagList from '../components/TagList'
import Tag from '../components/Tag'
import Footer from '../components/Footer'
import NewsletterSignup from '../components/NewsletterSignup'

const Template = ({children}) => (
    <StaticQuery
      query={graphql`
        query layoutQuery {
          allContentfulTag(sort: {fields:[title]}) {
            edges {
              node {
                title
                slug
                id
              }
            }
          }
          allContentfulTopMenu {
            edges {
              node {
                id
                title
                linkTo
              }
            }
          }
          allContentfulBurgerMenu {
            edges {
              node {
                id
                title
                linkTo
              }
            }
          }
        }
      `}
      render={data => (
          <>
          <Helmet>
            <title>{config.siteTitle}</title>
            <meta charSet="utf-8" />
            <html lang="en" />
            <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
            <meta name="description" content={config.siteDescription} />
            <meta property="og:title" content={config.siteTitle} />
            <meta property="og:url" content={config.siteUrl} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={config.siteTitle} />
          </Helmet>
          <ThemeProvider theme={theme}>
            <div className="siteContent" id="siteContent">
              <GlobalStyle/>
              <Wrapper>
                <Header topMenu={data.allContentfulTopMenu.edges} burgerMenu={data.allContentfulBurgerMenu.edges} />
                <Main id="pageWrap">
                  {children}
                </Main>
                <Sidebar>
                  <Social />
                  <SidebarItem padding="1.5em" bt="none" backgroundColor="#B29AB7">
                    <NewsletterSignup />
                  </SidebarItem>
                  <SidebarItem>
                    <Heading.H4 looksLike="H1" handwriting tac mb="0.5em">Topics</Heading.H4>
                    <TagList sidebar>
                      {data.allContentfulTag.edges.map(({ node: tag }) => (
                        <Tag key={tag.id} tag={tag} />
                      ))}
                    </TagList>
                  </SidebarItem>
                  <Footer />
                </Sidebar>
              </Wrapper>
            </div>
          </ThemeProvider>
        </>
      )}
    />
)

export default Template
