module.exports = {
  siteTitle: 'MOMNOTEBOOK',
  siteTitleAlt: 'MOMNOTEBOOK', // This allows an alternative site title for SEO schema.
  publisher: 'MOMNOTEBOOK.COM', // Organization name used for SEO schema
  siteDescription:
    'Serving 21st Century Moms Since 2018',
  siteUrl: 'https://momnotebook.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  shortTitle: 'MOMNOTEBOOK', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © MOMNOTEBOOK.COM', // Copyright string for the RSS feed
  rssAuthor: 'MOMNOTEBOOK.COM',
  facebook: '246839982710430',
  facebookUrl: 'https://www.facebook.com/Momnotebook-2084736128460128',
  instagramUrl: 'https://www.instagram.com/momnotebook',
  postsPerPage: 10
}
