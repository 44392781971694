import React from 'react'
import breakpoint from '../utils/breakpoints'
import styled from 'styled-components'

const SidebarWrapper = styled.section`
  margin-right: ${props => props.theme.size.sectionSpacing};
  min-width: 12em;
  width: 12em;

  ${breakpoint.lessThan('large')`
    border-top: solid 1px ${props => props.theme.colors.border};
    margin: ${props => props.theme.size.sectionSpacing} 0 0 0;
    min-width: 0;
    padding: ${props => props.theme.size.sectionSpacing} 0 0 0;
    width: 100%;
    overflow-x: hidden;
  `}
`

const Sidebar = props => {
  return (<SidebarWrapper>{props.children}</SidebarWrapper>
  )
}

export default Sidebar
